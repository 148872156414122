import React from "react";
import Select from "react-select";
import { sweetalert } from "../../App";
import { calculateAge } from "../../util/ValidationUtil";
import {
    buildRow,
    getLabel,
    getLabelIDsArray,
    getReactSelectAriaLabel,
    getSelectLabels,
    getlabelsFromIDs,
    parseDate,
} from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import { Patient } from "../../types/Patient";
import PhoneInput from 'react-phone-number-input'
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import PatientAPI from "../../network/PatientAPI";
import { AuthContext } from "../../context/AuthContext";
import SystemAPI from "../../network/SystemAPI";
import { withTranslation, Trans } from 'react-i18next';
import AutoComplete from "./Autocomplete";

interface ProfileManagementState {
    profile: Patient
    showInBetween
    showLoading
    genders
    ethnicities
    races
    states
    countries
    // facilities
    selectedLanguage
}
interface ProfileManagementProps {
    t
}
class ProfileManagement extends React.Component<ProfileManagementProps, ProfileManagementState> {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            showInBetween: false,
            showLoading: false,
            profile: {
                ID: null,
                uid: "",
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                dateOfBirth: '',
                streetAddress: '',
                city: '',
                state: '',
                zipcode: '',
                country: '',
                county: '',
                streetAddress2: '',
                customerID: null,
                genderID: null,
                ethnicityID: null,
                raceID: null,
                guardianFirstName: '',
                guardianLastName: '',
                textOptOut: false

            } as Patient,
            genders: [],
            ethnicities: [],
            races: [],
            states: [],
            countries: [],
            selectedLanguage: 'en'
        }
        this.modifyPatient = this.modifyPatient.bind(this);
        this.loadDataAndPatientInfo = this.loadDataAndPatientInfo.bind(this);
    }

    componentDidMount(): void {
        document.title = 'Profile Management | Oklahoma Department of Health';
        this.loadDataAndPatientInfo()
    }

    loadDataAndPatientInfo() {
        this.setState({ showLoading: true }, () => {
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ races: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ genders: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicities: data });
            });
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data });
            });

            PatientAPI.getUserProfileByUID(this.context.uid).then(response => {
                let profileData = response.data[0];
                this.setState({
                    profile: {
                        ID: profileData && profileData.id ? profileData.id : null,
                        uid: profileData && profileData.uid ? profileData.uid : null,
                        firstName: profileData && profileData.firstName ? profileData.firstName : this.context.firstName,
                        middleName: profileData && profileData.middleName ? profileData.middleName : this.context.middleName,
                        lastName: profileData && profileData.lastName ? profileData.lastName : this.context.lastName,
                        email: profileData && profileData.email ? profileData.email : this.context.email,
                        phoneNumber: profileData && profileData.phoneNumber ? profileData.phoneNumber : null,
                        dateOfBirth: profileData && profileData.dateOfBirth ? parseDate(profileData.dateOfBirth) : null,
                        streetAddress: profileData && profileData.streetAddress ? profileData.streetAddress : null,
                        city: profileData && profileData.city ? profileData.city : null,
                        state: profileData && profileData.state ? profileData.state : null,
                        zipcode: profileData && profileData.zipcode ? profileData.zipcode : null,
                        country: profileData && profileData.country ? (profileData.country === 'United States of America' ? 'United States' : profileData.country ? profileData.country : "") : "",
                        county: profileData && profileData.country ? profileData.county : null,
                        streetAddress2: profileData && profileData.streetAddress2 ? profileData.streetAddress2 : null,
                        genderID: profileData && profileData.genderID ? profileData.genderID : null,
                        ethnicityID: profileData && profileData.ethnicityID ? profileData.ethnicityID : null,
                        raceID: profileData && profileData.raceID ? profileData.raceID : null,
                        guardianFirstName: profileData && profileData.guardianFirstName ? profileData.guardianFirstName : '',
                        guardianLastName: profileData && profileData.guardianLastName ? profileData.guardianLastName : '',
                        textOptOut: profileData && profileData.TextOptOut ? profileData.TextOptOut : false
                    } as Patient,
                    showLoading: false
                }); // set state profile
            }); // getUserProfileByID...then
        }); // set state showLoading:true
    }

    handleSubmit() {
        this.setState({ showLoading: true }, () => {
            let tempDate = this.state.profile.dateOfBirth;
            if (typeof tempDate === 'object') {
                tempDate = parseDate(tempDate);
            }

            let patientInfoCopy = JSON.parse(JSON.stringify(this.state.profile));
            patientInfoCopy.firstName = patientInfoCopy.firstName ? patientInfoCopy.firstName.trim() : null;
            patientInfoCopy.middleName = patientInfoCopy.middleName ? patientInfoCopy.middleName.trim() : null;
            patientInfoCopy.lastName = patientInfoCopy.lastName ? patientInfoCopy.lastName.trim() : null;
            patientInfoCopy.email = patientInfoCopy.email ? patientInfoCopy.email.trim() : null;
            patientInfoCopy.dateOfBirth = tempDate ? tempDate : null;
            patientInfoCopy.streetAddress = patientInfoCopy.streetAddress ? patientInfoCopy.streetAddress.trim() : null;
            patientInfoCopy.streetAddress2 = patientInfoCopy.streetAddress2 ? patientInfoCopy.streetAddress2.trim() : null;
            patientInfoCopy.city = patientInfoCopy.city ? patientInfoCopy.city.trim() : null;
            patientInfoCopy.zipcode = patientInfoCopy.zipcode ? patientInfoCopy.zipcode.trim() : null;
            patientInfoCopy.county = patientInfoCopy.county ? patientInfoCopy.county.trim() : null;
            patientInfoCopy.country = patientInfoCopy.country ? patientInfoCopy.country.trim() : null;
            patientInfoCopy.phoneNumber = patientInfoCopy.phoneNumber ? patientInfoCopy.phoneNumber.trim() : null;
            patientInfoCopy.guardianFirstName = patientInfoCopy.guardianFirstName ? patientInfoCopy.guardianFirstName.trim() : '';
            patientInfoCopy.guardianLastName = patientInfoCopy.guardianLastName ? patientInfoCopy.guardianLastName.trim() : '';

            this.setState({ profile: patientInfoCopy });

            //validate patient info
            //validate child info
            let res = this.validatePatient(patientInfoCopy)
            if (!res.success) {
                this.setState({ showLoading: false })
                return sweetalert.fire({
                    icon: "error",
                    title: "",
                    text: this.props.t(res.error),
                });
            } else {
                this.setState({ showLoading: false })
                this.modifyPatient(this.state.profile);
            }

        })
    }

    validatePatient(s) {
        let patientValidation = {
            PatientFirstName: s.firstName,
            PatientMiddleName: s.middleName,
            PatientLastName: s.lastName,
            PatientDOB: s.dateOfBirth,
            PatientEmail: s.email,
            PatientGender: s.genderID,
            PatientAddress: s.streetAddress,
            // PatientAddress2: s.streetAddress2,
            // PatientCity: s.city,
            // PatientZip: s.zipcode,
            // PatientState: s.state,
            // PatientCounty: s.county,
            PatientCountry: s.country,
            PatientPhone: s.phoneNumber,
            PatientEthnicity: s.ethnicityID,
            PatientRace: s.raceID,
            GuardianFirstName: s.guardianFirstName,
            GuardianLastName: s.guardianLastName,
        }

        let validator = new Validator<any>()
            .withValidation("PatientFirstName", Validators.requireNotBlankAndLength(50, "First Name"));
        if (s.middleName && s.middleName.trim().length > 0) {
            validator = validator.withValidation("PatientMiddleName", Validators.requireNotBlankAndLength(50, "Middle Name"));
        }
        validator = validator.withValidation("PatientLastName", Validators.requireNotBlankAndLength(50, "Last Name"))
            .withValidation("PatientDOB", Validators.requireDOB(150, "Date of Birth"))
            .withComposedValidation("PatientEmail", new ValidationEntry(Validators.requireValidEmail("Email")), Validators.requireNotBlankAndLength(100, "Email"))
            .withSimpleValidation("PatientGender", Validators.requireNonNullValidator("Gender"))
            .withValidation("PatientAddress", Validators.requireNotBlankAndLength(500, "Street Address"));
        validator = validator.withComposedValidation("PatientPhone", new ValidationEntry(Validators.requireNonNullValidator("Phone")), new ValidationEntry(Validators.requirePhone("Phone")))
            .withSimpleValidation("PatientEthnicity", Validators.requireNonNullValidator("Ethnicity"))
            .withSimpleValidation("PatientRace", Validators.requireNonNullValidator("Race"));
        let validationResponse = validator.validate(patientValidation);
        if (!validationResponse.success) {
            return { success: false, error: validationResponse.error }
        }

        return { success: true };
    }

    modifyPatient(patient: Patient) {
        patient.guardianFirstName = patient.guardianFirstName ? patient.guardianFirstName : null;
        patient.guardianLastName = patient.guardianLastName ? patient.guardianLastName : null;

        this.setState({ showLoading: true }, async () => {
            try {
                let result = await PatientAPI.editPatient(patient);
                if (result.success) {
                    sweetalert.fire({ icon: 'success', title: '', text: this.props.t('Profile saved.') }).then(() => {
                        // send back to homepage
                        window['location'] = '/admin' as unknown as Location;
                    });
                } else {
                    sweetalert.fire({ icon: 'error', title: '', text: result.reason });
                    this.setState({ showLoading: false });
                }
            }
            catch (e) {
                console.log(e);
                this.setState({ showLoading: false });
            }
        });
    }

    render() {

        let countriesCopy = this.state.countries && this.state.countries.length > 0 ? JSON.parse(JSON.stringify(this.state.countries)) : [];
        let indexOfUS = countriesCopy.findIndex(obj => obj.value === 'United States');
        let [removedObj] = countriesCopy.splice(indexOfUS, 1);
        countriesCopy.unshift(removedObj)

        let translationGenders = this.state.genders && this.state.genders.length > 0 ? this.state.genders.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
        let translationEthnicities = this.state.ethnicities && this.state.ethnicities.length > 0 ? this.state.ethnicities.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
        let translationRaces = this.state.races && this.state.races.length > 0 ? this.state.races.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];

        return (
            <>
                <Overlay show_loading={this.state.showLoading} zIndex={100003} />
                <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100002} />
                {!this.state.showLoading &&
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 offset-lg-1 col-sm-12 col-md-12 col-lg-10 col-xl-10 pt-2">
                                <main id="main-content" tabIndex={-1} aria-label={this.props.t("Profile Management")}>
                                    <div className={"card"}>
                                        <div className="card-header">
                                            <h5 className="card-title d-inline-block" id="result_card_label">{this.props.t("Profile Management")}</h5>
                                        </div>
                                        <div className="card-body row">
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                {buildRow(this.props.t("Email"),
                                                    <input className={"form-control"}
                                                        id="EmailInput"
                                                        maxLength={50}
                                                        autoComplete={"off"}
                                                        type={"search"}
                                                        name={"Email"}
                                                        aria-label="Email"
                                                        placeholder="name@example.com"
                                                        onChange={(e) => {
                                                            this.setState((prevState) => ({
                                                                profile: {
                                                                    ...prevState.profile,
                                                                    email: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                        value={this.state.profile.email}
                                                    />
                                                    , this.props.t('Email')
                                                    , true
                                                )}
                                                {buildRow(this.props.t("First Name"),
                                                    <input
                                                        id="FirstNameInput"
                                                        className={"form-control"}
                                                        maxLength={50}
                                                        autoComplete={"off"}
                                                        aria-label="First Name"
                                                        type={"search"}
                                                        name={"First Name"}
                                                        onChange={(e) => {
                                                            this.setState((prevState) => ({
                                                                profile: {
                                                                    ...prevState.profile,
                                                                    firstName: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                        value={this.state.profile.firstName}
                                                    />
                                                    , this.props.t('First Name')
                                                    , true
                                                )}
                                                {buildRow(this.props.t('Middle Name'),
                                                    <input
                                                        id="MiddleNameInput"
                                                        className={"form-control"}
                                                        maxLength={50}
                                                        autoComplete={"off"}
                                                        type={"search"}
                                                        name={"Middle Name"}
                                                        aria-label="Middle Name"
                                                        onChange={(e) => {
                                                            this.setState((prevState) => ({
                                                                profile: {
                                                                    ...prevState.profile,
                                                                    middleName: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                        value={this.state.profile.middleName}
                                                    />
                                                    , this.props.t('Middle Name')
                                                )}
                                                {buildRow(this.props.t('Last Name'),
                                                    <input
                                                        id="LastNameInput"
                                                        className={"form-control"}
                                                        maxLength={50}
                                                        autoComplete={"off"}
                                                        type={"search"}
                                                        name={"Last Name"}
                                                        aria-label="Last Name"
                                                        onChange={(e) => {
                                                            this.setState((prevState) => ({
                                                                profile: {
                                                                    ...prevState.profile,
                                                                    lastName: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                        value={this.state.profile.lastName}
                                                    />
                                                    , this.props.t('Last Name')
                                                    , true
                                                )}
                                                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Date of Birth'}>
                                                    <label htmlFor={'PatientDOB'} className="col-12 col-sm-4 col-form-label text-left">{this.props.t('Date of Birth')}<section className="text-danger d-inline-block px-1">*</section></label>
                                                    <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'PatientDOB'}>
                                                        <input
                                                            id="PatientDOBInput"
                                                            min={'1000-01-01'}
                                                            max={new Date().toISOString().split('T')[0]}
                                                            className={'form-control'}
                                                            autoComplete={'off'}
                                                            type={'date'}
                                                            name={'PatientDOB'}
                                                            aria-label="Date of Birth"
                                                            value={this.state.profile && this.state.profile.dateOfBirth ? new Date(this.state.profile.dateOfBirth).toISOString().split('T')[0] : ''}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const [year, month, day] = inputValue.split('-');
                                                                if (Date.parse(inputValue)) {
                                                                    if (year && year.length > 4) {
                                                                        const correctedYear = year.slice(0, 4);
                                                                        const newDateValue = `${correctedYear}-${month}-${day}`;
                                                                        this.setState((prevState) => ({
                                                                            profile: {
                                                                                ...prevState.profile,
                                                                                dateOfBirth: new Date(newDateValue)
                                                                            }
                                                                        }));
                                                                    } else {
                                                                        this.setState((prevState) => ({
                                                                            profile: {
                                                                                ...prevState.profile,
                                                                                dateOfBirth: new Date(inputValue)
                                                                            }
                                                                        }));
                                                                    }
                                                                }
                                                            }
                                                            }
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                                {buildRow(this.props.t("Gender"),
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder={<div className="accessibilityText">Please Select...</div>}
                                                        noOptionsMessage={() => "No option"}
                                                        value={getLabel(this.state.profile.genderID, this.state.genders, this.props.t)}
                                                        aria-label={getReactSelectAriaLabel("Gender", getLabel(this.state.profile.genderID, this.state.genders, this.props.t), this.props.t)}
                                                        onChange={(e) => this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                genderID: e.value
                                                            }
                                                        }))}
                                                        className={"state_select"}
                                                        options={translationGenders}
                                                    />
                                                    , this.props.t("Gender")
                                                    , true
                                                )}
                                                {buildRow(this.props.t("Ethnicity"),
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder={<div className="accessibilityText">Please Select...</div>}
                                                        noOptionsMessage={() => "No option"}
                                                        value={getLabel(this.state.profile.ethnicityID, this.state.ethnicities, this.props.t)}
                                                        aria-label={getReactSelectAriaLabel("Ethnicity", getLabel(this.state.profile.ethnicityID, this.state.ethnicities, this.props.t), this.props.t)}
                                                        onChange={(e) => this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                ethnicityID: e.value
                                                            }
                                                        }))}
                                                        className={"state_select"}
                                                        options={translationEthnicities}
                                                    />
                                                    , this.props.t("Ethnicity")
                                                    , true
                                                )}
                                                {buildRow(this.props.t("Race"),
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder={<div className="accessibilityText">Please Select...</div>}
                                                        noOptionsMessage={() => "No option"}
                                                        value={getLabel(this.state.profile.raceID, this.state.races, this.props.t)}
                                                        aria-label={getReactSelectAriaLabel("Race", getLabel(this.state.profile.raceID, this.state.races, this.props.t), this.props.t)}
                                                        onChange={(e) => this.setState((prevState) => ({
                                                            profile: {
                                                                ...prevState.profile,
                                                                raceID: e.value
                                                            }
                                                        }))}
                                                        className={"state_select"}
                                                        options={translationRaces}
                                                    />
                                                    , this.props.t("Race")
                                                    , true
                                                )}
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                {buildRow(this.props.t("Phone Number"),
                                                    <PhoneInput
                                                        id={'PhoneNumberInput'}
                                                        placeholder={this.props.t("Enter phone number (10 digit)")}
                                                        aria-label={this.props.t("Phone Number")}
                                                        value={this.state.profile.phoneNumber}
                                                        onChange={(e) => this.setState((prevState) => ({
                                                            profile: { ...prevState.profile, phoneNumber: e }
                                                        }))}
                                                        defaultCountry="US"
                                                    />, this.props.t("Phone Number")
                                                    , true
                                                )}

                                                <div key={"PatientAutoComplete"} className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Street Address')}>
                                                    <label id={'Patient Address'} htmlFor={'Patientautocomplete'} className="col-12 col-sm-4 col-form-label text-left px-2">{this.props.t('Street Address')}<section className="text-danger d-inline-block px-1">*</section></label>
                                                    <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'Patient Address'}>
                                                        <AutoComplete key={"PatientAutocomplete"} id={"Patientautocomplete"}
                                                            value={this.state.profile.city ? `${this.state.profile.streetAddress}, ${this.state.profile.city}, ${this.state.profile.state} ${this.state.profile.zipcode}` : this.state.profile.streetAddress}
                                                            onChange={(addr) => {
                                                                if (!(addr)) {
                                                                    this.setState({ profile: { ...this.state.profile, streetAddress: "", city: "", state: "", zipcode: "", county: "", country: "" } })
                                                                }
                                                                this.setState((prevState) => ({ profile: { ...prevState.profile, streetAddress: addr } }))
                                                            }}
                                                            updateFormData={(addr, city, state, zip, county, country) => {
                                                                this.setState({ profile: { ...this.state.profile, streetAddress: addr, city: city, state: state, zipcode: zip, county: county, country: country } })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-check text-center">
                                                    <input className="form-check-input" type="checkbox" id="optOut"
                                                        checked={this.state.profile?.textOptOut ? true : false}
                                                        onChange={(e) =>
                                                            this.setState((prevState) => ({
                                                                profile: {
                                                                    ...prevState.profile,
                                                                    textOptOut: e.target.checked
                                                                }
                                                            }))} />
                                                    <label className="form-check-label" htmlFor="optOut">
                                                        Opt out of text messages about the status of my immunization exemption submission.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="button" className="btn btn-primary" onClick={() => { this.handleSubmit() }}> {this.props.t("Submit")} </button>
                                            <p className="float-right d-inline-block m-0"><section className="text-danger d-inline-block px-1">*</section>{this.props.t("Required Fields")}</p>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
}

export default withTranslation()(ProfileManagement)